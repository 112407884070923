.App {
  text-align: center;
}
body{
  font-family: Futura, Futura-Medium, "Futura Medium", "Century Gothic", CenturyGothic, "Apple Gothic", AppleGothic, "URW Gothic L", "Avant Garde", sans-serif !important;
  color: #444;
}
#footer {
  background-color: #3a3737;
  text-align: center;
  font-size: .8em;
  color: #ddd;
  bottom: 0px;
}
#footer-mobile{
  background-color: #3a3737;
  text-align: center;
  font-size: .9em;
  color: #ddd;
  position:relative;
  bottom: 0px;
}
#footer a, #footer-mobile a{
  color: #ddd;
  font-weight: bold;
  text-decoration: underline;
}
#footer hr{
  border-color: #7eb04e;
  width: 70%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.footer-tag-line{
  color: #7eb04e;
  font-size: 14px;
  font-weight: bold;
}
#footer .footer-website{
  text-align: right;
  text-transform: uppercase;
}
#footer .footer-phone{
  text-align: left;
}
#footer .footer-email{
  text-align: left;
  text-transform: uppercase;
}
.footer-email{
  text-transform: uppercase;
}
#footer .footer-social{
  text-align: right;
}
.footer-social a{
  padding-left: .5rem;
  padding-right: .5rem;
}
#footer-mobile ul{
  list-style-type: none;
  width: 100%;
  padding-inline-start: 0px;
  text-align: center;
}
.footer-copyright, .footer-privacy-policy, .footer-tou{
  font-size: 11px;
  padding: 10px;
}
.ulabsmodal-dialog{
  font-size: 13px;
  width: 90%;
  max-width: 90% !important;
}