// styles
.page {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #60a047;
  color: #232129;
  fontfamily: Helvetica, sans-serif, serif;
  button {
    border-style: none;
    background-color: transparent;
    text-align: left;
  }
}
.mainContainerDiv {
  background-color: #fefefe;
  flex: 0 1 90%;
  border: 1px solid #444;
  padding: 10px;
  max-width: 1200px;
  min-width: 360px;
}
.headerDiv {
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  color: #222;
  padding: 16px 22px 16px 32px;
  font-family: Helvetica, Trebuchet MS, sans-serif;
  font-weight: 300;
  align-items: center;
  h1 {
    color: #60a047;
    font-family: Helvetica, Trebuchet MS, sans-serif;
    font-size: 1.6em;
  }
  p {
    marginbottom: 48px;
  }
  img {
    max-width: 200px;
    padding-bottom: 10px;
  }
  .logoImg {
    width: 90%;
    float: left;
    max-height: 100%;
  }
}
.headerLeftDiv {
  flex: 0 0 25%;
  align-self: start;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}
.headerRightDiv {
  flex: 0 0 65%;
  min-width: 250px;
}

.comingSoon {
  color: red;
  position: relative;
  top: -93px;
  left: 44px;
  height: 0px;
}
.cardscontainer {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.card {
  flex: 0 0 22%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.cardImg {
  flex: 1 1 100%;
  max-width: 250px;
}
.link {
  color: #333;
  fontweight: bold;
  fontsize: 16px;
  verticalalign: 5%;
}
.helpLinks {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  a {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 300px;
    height: 200px;
    flex: 0 0 30%;
    background-color: #9dda5b;
    border-radius: 8px;
    span {
      text-align: center;
      flex: 0 0 10%;
    }
    img {
      width: 25%;
      text-align: center;
      flex: 0 0 22%;
      padding: 10px;
    }
  }

  div {
    flex: 0 0 15%;
    padding-top: 60px;
    padding-bottom: 40px;
    text-align: center;
    transform: scaleY(1.5);
    font-size: 2.5em;
    font-weight: bold;
    font-family: Trebuchet MS, Helvetica, sans-serif;
    color: #60a047;
  }
}
.helpHeading {
  padding: 32px 18px 18px 18px;
  text-align: center;
  transform: scaleY(1.5);
  font-size: 2.5em;
  font-weight: bold;
  font-family: Trebuchet MS, Helvetica, sans-serif;
  color: #60a047;
}
