.CreateVidComponent {
	margin: 20px;
}

.CreateVidComponent label {
	text-align: right;
}

form .btn {
	float: right;
	margin-bottom: 20px;
}
