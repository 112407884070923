label,
.form-label {
	font-weight: bold;
}

form .btn {
	float: right;
	margin-bottom: 20px;
}

.form-group .is-invalid div {
	border-color: #dc3545;
}
