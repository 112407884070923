
#logo_col{
  min-width: 150px;
}
#logoimg{
  padding: 15px 0px 15px 15px;
}
#userstatus_col button{
  background-color: transparent;
  color: #034ea2;
}
#userpanel_greeting{
  text-align: right;
  padding-top: 20px;
}

#headertxt_col{
  padding-top: 20px;
  text-align: left;
  font-family: Futura, Futura-Medium, "Futura Medium", "Century Gothic", CenturyGothic, "Apple Gothic", AppleGothic, "URW Gothic L", "Avant Garde", sans-serif !important;
  font-weight: bold;
  color: #666;
}

#videoicon{
  margin: 7px ;
  max-height: 50px;
  max-width: 90px;
}
.viewall-button{
  margin-top: 12px;
  width: 70%;
  float: right;
}
#maincontent{
  margin-bottom: 70px;
  
}

.wht-fluid-container{
    background-color: #fdfdfd;
    padding: 20px;
}
.search-bar{
  -webkit-box-shadow: 0 5px 5px -3px #555;
   -moz-box-shadow: 0 4px 5px -3px #555;
        box-shadow: 0 4px 5px -3px #555;
        }
.search-bar .searchbox, .search-bar .dropdown{
  margin-top: 12px;
}

#c-search-bar {
    background-color: #8ece77;
}
#c-search-bar .viewall-button{
  background-color: #2b8a2d;
}

#c-search-bar .viewall-button a {
  color: #eee;;
}

#training-search-bar {
    background-color: #fa8742;
}
#training-search-bar .viewall-button{
  background-color: #eee;
  color: #333;
}

#training-search-bar .viewall-button a {
  color: #333;;
}
#training-search-bar .viewall-button:hover{
  background-color: #aaa;
}

#admin-search-bar {
    background-color: #e60000;
}
#admin-search-bar .viewall-button{
  background-color: #eee;
}
#admin-search-bar .viewall-button:hover{
  background-color: #e60000;
}
#admin-search-bar .viewall-button a {
  color: #333;;
}














