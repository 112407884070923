#e-dash-content,
#c-dash-content {
	background-image: url(https://s3.amazonaws.com/ulabs.info/media/dashboard-bkg1.png);
	min-height: calc(100vh - 175px);
	background-size: cover;
	padding-bottom: 15px;
}
#employee-welcome-text,
#customer-welcome-text {
	font-size: 25px;
	font-weight: bold;
	text-align: center;
	padding-top: 2%;
}
#employee-subhead-text,
#customer-subhead-text {
	color: #034ea2;
	text-align: center;
	font-size: 25px;
	font-weight: bold;
}
#e-dash-content .card,
#c-dash-content .card {
	margin: auto;
	margin-top: 5%;
	-webkit-box-shadow: 0 45px 56px -31px #555;
	-moz-box-shadow: 0 45px 56px -31px #555;
	box-shadow: 0 45px 56px -31px #555;
}
#employee-vids-card .card-text img,
#customer-vids-card .card-text img {
	float: left;
	padding: 15px 15px 15px 0px;
}
#employee-posts-card-body {
	text-align: center !important;
	padding: 0rem 1.25rem 1.25rem 1.25rem;
}
#employee-posts-card-text {
	margin-bottom: -6px;
}
#people-cardimage {
	float: none !important;
	max-height: 87px;
	max-width: 100%;
	margin-top: 22px;
	margin-bottom: 7px;
}
#e-dash-content .card-text,
#c-dash-content .card-text {
	color: #eee;
	font-size: 1.05em;
}

#employee-vids-card,
#employee-posts-card {
	border-radius: 10px;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db358+39,fa8843+81,f66209+96&0.9+0,0.75+100 */
	background: -moz-linear-gradient(
		top,
		rgba(125, 179, 88, 0.9) 0%,
		rgba(125, 179, 88, 0.84) 39%,
		rgba(250, 136, 67, 0.78) 81%,
		rgba(246, 98, 9, 0.76) 96%,
		rgba(246, 98, 9, 0.75) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		rgba(125, 179, 88, 0.9) 0%,
		rgba(125, 179, 88, 0.84) 39%,
		rgba(250, 136, 67, 0.78) 81%,
		rgba(246, 98, 9, 0.76) 96%,
		rgba(246, 98, 9, 0.75) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		rgba(125, 179, 88, 0.9) 0%,
		rgba(125, 179, 88, 0.84) 39%,
		rgba(250, 136, 67, 0.78) 81%,
		rgba(246, 98, 9, 0.76) 96%,
		rgba(246, 98, 9, 0.75) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e67db358', endColorstr='#bff66209',GradientType=0 ); /* IE6-9 */
}

#customer-vids-card {
	border-radius: 10px;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4f8e24+0,8dc666+100&0.9+0,0.75+100 */
	background: -moz-linear-gradient(
		top,
		rgba(79, 142, 36, 0.9) 0%,
		rgba(141, 198, 102, 0.75) 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		top,
		rgba(79, 142, 36, 0.9) 0%,
		rgba(141, 198, 102, 0.75) 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to bottom,
		rgba(79, 142, 36, 0.9) 0%,
		rgba(141, 198, 102, 0.75) 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e64f8e24', endColorstr='#bf8dc666',GradientType=0 ); /* IE6-9 */
}

#employee-vids-card .row,
#employee-posts-card .row,
#customer-vids-card .row {
	margin-top: 0.7rem;
	margin-bottom: 0.7rem;
}
#employee-vids-card .form-group,
#employee-posts-card .form-group,
#customer-vids-card .form-group {
	margin-bottom: 0px;
}
#view-customer-vids-button,
#view-training-vids-button,
#view-training-posts-button {
	width: 100%;
}

#employee-vids-card #view-training-vids-button,
#employee-posts-card #view-training-posts-button,
#customer-vids-card #view-customer-vids-button,
#employee-vids-card #category-dropdown-button,
#employee-posts-card #category-dropdown-button,
#customer-vids-card #category-dropdown-button,
#employee-vids-card .search-button,
#employee-posts-card .search-button,
#customer-vids-card .search-button {
	text-transform: uppercase;
	font-weight: bold;
	color: #777;
	font-size: 0.8rem;
}
