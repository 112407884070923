.auth-main-row{
    background-image: url('https://s3.amazonaws.com/ulabs.info/media/bkg1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: calc(100vh - 200px);
    padding: 0px 0px 15px 0px;
    margin: 0px;
}
.auth-main-card{
    width: 32rem;
    max-width: 100%;
    margin-top: 5%;
    margin-bottom: auto;
    background-color: rgb(104, 189, 73, .82) !important;
    border-radius: 6px !important;
}
.inputtip .link{
    cursor: pointer;
    color: #fefefe;
    text-decoration: underline;
}
.auth-main-card .card-title{
    font-size: 1.5em;
    text-align: center;
}
.auth-main-card h1{
    text-transform: uppercase;
    font-weight: bold;
}
.auth-main-card h2{
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.1rem;
    ;
}
.auth-main-card .card-title h3{
    font-size: 1.0rem;
    font-weight: bold;
    margin-top: .7rem;
}
.auth-main-card label{
    font-weight: bold;
    font-size: 1.05em;
}
.auth-main-card form .btn{
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    color: rgb(104, 189, 73);
}
.auth-main-card .btn-link{
    color: #f9f9f9;
    text-transform: uppercase;
    border-bottom: 1px solid #f9f9f9;
    padding: 0px;
    margin-bottom: 3px;
    font-weight: bold
}
.auth-main-card .btn-link:hover{
    color: #ddd;
    text-transform: uppercase;
    border-bottom: 1px solid #ddd;
    padding: 0px;
    text-decoration: none;
    margin-bottom: 3px;
    font-weight: bold
}
.auth-main-card label{
    text-transform: uppercase;
    color: #f9f9f9;
    margin-bottom: .1rem;
}
.auth-main-card .card-text{
    font-weight: bold;
    color: #333;
    text-align: center;
}
.auth-main-card .card-text b{
    text-transform: uppercase;
    color: #222;
}
.auth-main-card .text-muted{
    font-weight: bold;
}
.auth-main-card .inputtip{
    color: #222;
    font-size: .95em;
    margin-top: -.75rem;
}
.error-modal .modal-content{
    background-color: #f26c4f;
    text-align: center;
}
.error-modal .error-message{
    font-size: 1.25em;
    text-transform: uppercase;
    color: #fefefe;
    font-weight: bold;
}
.error-modal .error-message .btn-link{
    color: #f9f9f9;
    text-transform: uppercase;
    border-bottom: 1px solid #f9f9f9;
    padding: 0px;
    margin-bottom: 3px;
    font-weight: bold
}
.error-modal .error-message .btn-link:hover{
    color: #ddd;
    text-transform: uppercase;
    border-bottom: 1px solid #ddd;
    padding: 0px;
    text-decoration: none;
    margin-bottom: 3px;
    font-weight: bold
}
.error-modal .modal-close-button{
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 20px;
    font-weight: bolder;
    color: #fefefe;
    text-decoration: none;
}
.modal-backdrop{
    background-color: #555;
}
.modal-backdrop.show{
    opacity: .25 !important;
}
.invalid-feedback{
    color: #9a0000 !important;
    font-weight: bold;
}
.forgotpass-form2{
    padding-top: 20px;
}
#forgotpass-card{
    margin-bottom:20px;
}
#footer-mobile{
    margin-top:0px;
}