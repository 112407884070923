#vid_info_col{
    margin-top: 30px;
}
#vid_info_col h1{
    font-size: 25px;
    font-weight: bold;
}

#vid_info_col h3{
    font-size: 21px;
}

#video_player_col{
    padding: 20px;
}