#category-dropdown-button{
    color: #333;
    width:100%;
}

#category-dropdown-wrapper .dropdown-toggle::after {
    display: inline-block;
    margin-left: 1em;
    vertical-align: middle;
    content: "";
    border-top: .75em solid;
    border-right: .5em solid transparent;
    border-bottom: 0;
    border-left: .5em solid transparent;
}