#vid_info_col{
    margin-top: 30px;
}
#vid_info_col h1{
    font-size: 25px;
    font-weight: bold;
}

#vid_info_col h3{
    font-size: 21px;
}

#user_badge_img{
    max-height: 20px;
    max-width: 20px;
    padding-right: 2px;
    margin-top: -3px;
}

#longdesc_col{
    padding-top: 20px;
    padding-bottom: 20px;
}

#post_carousel img,
#video_player_col video
{
    max-height: 450px;
    margin-left: auto;
    margin-right: auto;
}

#post_carousel .carousel-inner{
    background-color: #efefef;
}
#post_carousel .carousel-caption{
    font-size: 1.2em;
    font-weight: bold;
    color: rgb(180,70,0);
    background-color: rgb(239, 239, 239, 0.7);
    position: relative;
    left: 0px;
    bottom: 0px;
    width: 100%;
}
#post_carousel .carousel-indicators li{
    height: 5px;
    background-color: rgb(200,76,0);
}
.carousel-item{
    text-align: center;
}
.carousel-control-prev-icon,
.carousel-control-next-icon
{
    background-color: #000 !important;
    border-radius: 10px ;
    height: 50px !important;
    width: 30px !important;
}
