#videos_container{
    background-color: #fefefe;
}
#poststable_container{
    padding-top: 0px;
}

#add_contribution_col{
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: bold;
}
#filterby_notice_col{
    display: flex;
    align-items: center;
    font-size: 1em;
}
#add_contribution_col button{
    margin-left: 8px;
}
#badges_key_col{
    text-align: right;
}
#badges_key_img{
    max-height: 50px;
}
